import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { Company } from '@app/core/models/company.model';
import { JOURNEY_TYPES_NAMES } from '@app/core/models/journeys.model';
import { JourneyType } from '@app/core/services/journey.api.service';
import { JourneyQueryParams } from '@app/shared/create-planning/models/create-planning.model';
import { FnlButtonDirective, FnlPaperModule } from 'fnl-ui';

@Component({
  selector: 'app-new-journeys-widget',
  templateUrl: './new-journeys-widget.component.html',
  styleUrls: ['./new-journeys-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconModule,
    CommonModule,
    FnlPaperModule,
    RouterModule,
    MatButtonModule,
    FnlButtonDirective
  ]
})
export class NewJourneysWidgetComponent implements OnInit{
  @Input() company: Company;
  @Input() enableGtmJourneyLink: boolean;
  
  journeyQueryParams: JourneyQueryParams;

  readonly journeyTypesNames = JOURNEY_TYPES_NAMES;
  readonly JourneyType = JourneyType;

  ngOnInit(): void {
    this.journeyQueryParams = {
      companyUid: this.company?.uid,
      companyPrivate: this.company?.private,
    };
  }
}
