import { BaseItem } from '@app/core/models/common.model';
import { IndustryCode } from '@app/core/models/industry.model';
import { MetricGoalType, UnitsContainer } from '@app/core/models/metric.model';
import { Po1Base } from '@app/core/services/power-of-one.service';

export enum FishboneStepType {
  Indicator,
  Goal,
  BusinessFunction,
  Strategy,
  Initiative,
  KPI,
  Solution,
  SolutionAreas,
}

export enum FishboneType {
  ByIndustry,
  ByMetric,
  ByCompany,
}

export enum BDI {
  Metric,
  BusinessFunction,
  Solution,
}

export interface FishboneStep {
  uid: string;
  title: string;
  order: number;
  type: FishboneStepType;
}

export interface ExtendedFishboneStep extends FishboneStep {
  limit: number;
  required: boolean;
  singleMode: boolean;
  hasPO1: boolean;
}

export interface FishboneRow extends BaseItem {
  description?: string;
  skipStep?: boolean;
  childItems?: FishboneRow[];
  po1?: number;
}

export interface FishboneRowGroup extends FishboneRow {
  items: FishboneRow[];
}

export interface BDIFishboneRow extends FishboneRow {
  type: BDI;
}

export interface FishboneCombination {
  step: ExtendedFishboneStep;
  rows: FishboneRowGroup[];
}

export interface FishboneSelection {
  step: ExtendedFishboneStep;
  rows?: FishboneRow[];
  rowsUid: string[];
  skippedGroups?: string[];
}

export interface FishboneIndicator extends FishboneStep {
  fishboneComponents: FishboneStep[];
}

export interface PreparedFishboneIndicators {
  firstStepRows: BDIFishboneRow[];
  indicators: {
    [stepUId: string]: ExtendedFishboneStep[];
  };
}

export interface FishboneGoal {
  absoluteValueDisplayText?: string;
  goal: MetricGoalType;
  metricGroupOrder: number;
  order: number;
  relativeValueDisplayText: string;
  uid: string;
  units: UnitsContainer;
}

export interface FishboneGoalGroup extends FishboneRow {
  items: FishboneGoal[];
}

export interface FishboneShort {
  fishBoneWasComposed: boolean;
  selectedBdIndicatorUid?: string | string[];
  selectedFMetricsUids?: string[];
  selectedStrategiesUids?: string[];
  selectedInitiativesUids?: string[];
  selectedBFunctionsUids?: string[];
  selectedKpisUids?: string[];
  selectedSolutionsUids?: string[];
  selectedSolutionAreaUids?: string[];
}

export const TYPE_TO_SHORT_MAP = {
  [FishboneStepType.Indicator]: 'selectedBdIndicatorUid',
  [FishboneStepType.Goal]: 'selectedFMetricsUids',
  [FishboneStepType.BusinessFunction]: 'selectedBFunctionsUids',
  [FishboneStepType.Strategy]: 'selectedStrategiesUids',
  [FishboneStepType.Initiative]: 'selectedInitiativesUids',
  [FishboneStepType.KPI]: 'selectedKpisUids',
  [FishboneStepType.Solution]: 'selectedSolutionsUids',
  [FishboneStepType.SolutionAreas]: 'selectedSolutionAreaUids',
};

export const DEFAULT_FISHBONE_ROW_GROUP: FishboneRowGroup = {
  uid: '00000000-0000-0000-0000-000000000000',
  name: '',
  items: [],
};

export const SKIP_FISHBONE_ROW: FishboneRow = {
  uid: '-1',
  name: 'Skip the Step',
  skipStep: true,
};

export type FishbonePo1Params = Po1Base & {
  industryCode?: IndustryCode;
};
