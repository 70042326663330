import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dictionary, isNil, omitBy } from 'lodash';
import { catchError, map, Observable, of } from 'rxjs';

import { MetricChartView } from '../models/metric-chart.model';
import { MetricsUnit } from '../models/metric.model';
import {
  KPIFormulasData,
  KPIPO1ToImprove,
  KPIPO1Value,
  MetricPO1ToImprove,
  MetricPO1Value,
  Po1Metric,
} from '../models/powerOfOne.model';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';

export type Po1Base = {
  industryUid?: string;
  subIndustryUid?: string;
  companyUid?: string;
  currencyUid?: string;
  analysisType?: MetricChartView;
  targetRevenue?: number;
  businessSegmentUid?: string;
  geographicalSegmentUid?: string;
  industryRevenueGroupUid?: string;
};

export type MetricsPo1 = Po1Base & {
  metrics: MetricPO1ToImprove[];
};

export type KPIsPo1 = Po1Base & {
  kPIs: KPIPO1ToImprove[];
};

export type Po1Entity = {
  companyUid?: string;
  privateCompanyUid?: string;
  sicUid?: string;
  subindustryUid?: string;
  industryUid?: string;
};

@Injectable({
  providedIn: 'root',
})
export class PowerOfOneService {
  static readonly blockCid = 'PowerOfOneService';
  constructor(private _apiService: ApiService) {}

  getMetricsPo1(
    forCompany = false,
    {
      industryUid,
      industryRevenueGroupUid,
      companyUid,
      currencyUid,
      analysisType,
      targetRevenue,
      businessSegmentUid,
      geographicalSegmentUid,
      metrics,
    }: MetricsPo1,
    blockCid?: string
  ): Observable<MetricPO1Value[]> {
    if (metrics.length > 0) {
      const serializedMetrics = {};
      for (let i = 0; i < metrics.length; i++) {
        serializedMetrics[`metrics[${i}].uid`] = metrics[i].metricUid;
        serializedMetrics[`metrics[${i}].powerOfOneValue`] =
          metrics[i].powerOfOneValue;
      }
      const dataObj: { [key: string]: string } = {
        ...serializedMetrics,
      };
      let url: string;
      if (forCompany) {
        dataObj.currencyUid = currencyUid;
        dataObj.industryUid = industryUid;
        dataObj.companyUid = companyUid;
        dataObj.analysisType = analysisType?.toString();
        if (
          environment.alwaysSegments ||
          analysisType === MetricChartView.Years
        ) {
          dataObj.businessSegmentUid = businessSegmentUid;
          dataObj.geographicalSegmentUid = geographicalSegmentUid;
        }
        url = 'company/Metric/power-of-one';
      } else {
        dataObj.targetRevenue = targetRevenue?.toString();
        dataObj.industryRevenueGroupUid = industryRevenueGroupUid;
        url = 'industryResearch/Metric/power-of-one';
      }

      const params = new HttpParams({
        fromObject: omitBy(dataObj, isNil),
      });

      return this._apiService
        .get<MetricPO1Value[]>(url, { params }, blockCid)
        .pipe(
          map((values: MetricPO1Value[]) =>
            metrics.map((metric: MetricPO1ToImprove) => ({
              uid: metric.metricUid,
              improvement: metric.powerOfOneValue,
              value: values.find((value) => metric.metricUid === value.uid)
                ?.value,
            }))
          ),
          catchError(() => of([]))
        );
    } else {
      return of([]);
    }
  }

  /**
   * @deprecated use
   * {@link MetricRepository.getKpisPowerOfOnesList} and
   * {@link IndustryResearchMetricRepository.getKpisPowerOfOnesList} instead
   */
  getKPIsPo1(
    forCompany = false,
    {
      industryUid,
      subIndustryUid,
      companyUid,
      currencyUid,
      analysisType,
      targetRevenue,
      businessSegmentUid,
      geographicalSegmentUid,
      industryRevenueGroupUid,
      kPIs,
    }: KPIsPo1,
    blockCid?: string
  ): Observable<KPIPO1Value[]> {
    if (kPIs.length > 0) {
      const dataObj: Dictionary<any> = {
        kpis: kPIs,
      };
      let url: string;
      if (forCompany) {
        dataObj.currencyUid = currencyUid;
        dataObj.companyUid = companyUid;
        dataObj.industryUid = industryUid;
        dataObj.subIndustryUid = subIndustryUid;
        dataObj.analysisType = analysisType;
        if (
          environment.alwaysSegments ||
          analysisType === MetricChartView.Years
        ) {
          dataObj.businessSegmentUid = businessSegmentUid;
          dataObj.geographicalSegmentUid = geographicalSegmentUid;
        }
        url = 'company/metric/kpi-power-of-one';
      } else {
        dataObj.targetRevenue = targetRevenue;
        dataObj.industryRevenueGroupUid = industryRevenueGroupUid;
        url = 'industryResearch/Metric/kpi-power-of-one';
      }

      return this._apiService
        .post<KPIPO1Value[]>(url, omitBy(dataObj, isNil), blockCid, -1)
        .pipe(
          map((values: KPIPO1Value[]) =>
            kPIs.map((kPI: KPIPO1ToImprove) => {
              const myValue =
                values.find((value) => kPI.uid === value.uid) || {};
              return {
                uid: kPI.uid,
                conservative: kPI.conservativeValue,
                aggressive: kPI.aggressiveValue,
                ...myValue,
              };
            })
          ),
          catchError(() => of([]))
        );
    } else {
      return of([]);
    }
  }

  getKPIFormulas(
    industryUid: string,
    subIndustryUid: string,
    kpiUids: string[],
    currencyUid?: string,
    blockCid?: string
  ): Observable<KPIFormulasData[]> {
    return this._apiService
      .post<{ items: KPIFormulasData[] }>(
        'industryResearch/Industry/kpi-formula',
        omitBy(
          {
            industryUid,
            subIndustryUid,
            currencyUid,
            kpiUids,
          },
          isNil
        ),
        blockCid,
        -1
      )
      .pipe(
        map(({ items }) =>
          items.map(
            (item) =>
              ({
                ...item,
                benchmarks: item.benchmarks.map((benchmark) =>
                  benchmark.unit === MetricsUnit.Percent
                    ? benchmark
                    : { ...benchmark, unit: MetricsUnit.Number }
                ),
              } as KPIFormulasData)
          )
        ),
        catchError(() => of([] as KPIFormulasData[]))
      );
  }

  getMetricsWithKPI(
    industryUid: string,
    businessFunctionUid?: string,
    solutionUid?: string,
    blockCid = PowerOfOneService.blockCid
  ): Observable<Po1Metric[]> {
    return this._apiService
      .get<{ items: Po1Metric[] }>(
        'industryResearch/metric/metrics-kpis-power-of-one',
        {
          params: new HttpParams({
            fromObject: omitBy(
              {
                industryUid,
                businessFunctionUid,
                solutionUid,
              },
              isNil
            ),
          }),
        },
        blockCid
      )
      .pipe(
        map((data) => data.items),
        catchError(() => of([]))
      );
  }

  getMetricsWithKPIAndOverrides(
    userUid: string,
    entity: Po1Entity,
    businessFunctionUid?: string,
    solutionUid?: string,
    blockCid = PowerOfOneService.blockCid
  ): Observable<Po1Metric[]> {
    return this._apiService
      .get<{ items: Po1Metric[] }>(
        `fishbone/users/${userUid}/power-of-one/metrics-kpis`,
        {
          params: new HttpParams({
            fromObject: omitBy(
              {
                ...entity,
                businessFunctionUid,
                solutionUid,
              },
              isNil
            ),
          }),
        },
        blockCid
      )
      .pipe(
        map((data) => data.items),
        catchError(() => of([]))
      );
  }

  saveMetricImprovement(
    userUid: string,
    entityParams: Po1Entity,
    improvementData: MetricPO1ToImprove,
    blockCid = PowerOfOneService.blockCid
  ): Observable<boolean> {
    return this._apiService
      .put(
        `fishbone/users/${userUid}/power-of-one/metrics/${improvementData.metricUid}`,
        {
          improvement: improvementData.powerOfOneValue,
        },
        {
          params: new HttpParams({
            fromObject: omitBy(entityParams, isNil),
          }),
        },
        blockCid
      )
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  saveKPIImprovement(
    userUid: string,
    entityParams: Po1Entity,
    improvementData: KPIPO1ToImprove,
    blockCid = PowerOfOneService.blockCid
  ): Observable<boolean> {
    return this._apiService
      .put(
        `fishbone/users/${userUid}/power-of-one/kpis/${improvementData.uid}`,
        {
          conservativeImprovement: improvementData.conservativeValue,
          aggressiveImprovement: improvementData.aggressiveValue,
        },
        {
          params: new HttpParams({
            fromObject: omitBy(entityParams, isNil),
          }),
        },
        blockCid
      )
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  clearAllSavedImprovements(
    userUid: string,
    entityParams: Po1Entity,
    blockCid = PowerOfOneService.blockCid
  ): Observable<boolean> {
    return this._apiService
      .delete(
        `fishbone/users/${userUid}/power-of-one/metrics-with-kpis`,
        {
          params: new HttpParams({
            fromObject: omitBy(entityParams, isNil),
          }),
        },
        blockCid
      )
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }
}
